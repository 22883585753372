import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Group,
  Stack,
  Text,
  Button,
  AppShell,
  Title,
  Box,
  NavLink,
  Space,
} from "@mantine/core";
import ImagePicker from "./imagePicker";

import { useNavigate, useLocation } from "react-router-dom";
import { LanguageContext } from "../context/language";
import { useTranslation } from "react-i18next";
import { useSignOut, useIsAuthenticated, useAuthUser } from "react-auth-kit";
import { useServerApi } from "../hooks/useServerApi";

import {
  IconGauge,
  IconHome,
  IconMenuOrder,
  IconFingerprint,
  IconActivity,
  IconLogout,
  IconMoneybag,
  IconCurrencyDollar,
  IconGift,
  IconSettings,
} from "@tabler/icons-react";
import { IconChevronRight } from "@tabler/icons-react";
import { useForm } from "@mantine/form";
// const { useTranslation } = require("react-i18next");

const NavBar = ({ w = 280, toggle }) => {
  //   const { t: translate } = useTranslation();
  const isAuthenticated = useIsAuthenticated();
  const signOut = useSignOut();
  const navigate = useNavigate();
  const authUser = useAuthUser();
  const [api] = useServerApi();
  const [image, setImage] = useState(authUser().image);

  const { language, changeLanguage } = useContext(LanguageContext);

  const handleChangeLanguage = (lng) => {
    changeLanguage(lng);
  };

  const data = [
    {
      icon: IconHome,
      label: "Home",
      // description: "Item with description",
      action: () => {
        navigate("/home");
      },
    },
    {
      icon: IconMenuOrder,
      label: "My Orders",
      // description: "Item with description",
      action: () => {
        navigate("/orders");
      },
    },
    {
      icon: IconCurrencyDollar,
      label: "Commissions",
      // description: "Item with description",
      action: () => {
        navigate("/commissions");
      },
    },
    {
      icon: IconMoneybag,
      label: "Payrolls",
      // description: "Item with description",
      action: () => {
        navigate("/payrolls");
      },
    },
    {
      icon: IconGift,
      label: "Credits",
      // description: "Item with description",
      action: () => {
        navigate("/credits");
      },
    },
    {
      icon: IconSettings,
      label: "Settings",
      // description: "Item with description",
      action: () => {
        navigate("/settings");
      },
    },
    //   {
    //     icon: IconFingerprint,
    //     label: "Security",
    //     rightSection: <IconChevronRight size="1rem" stroke={1.5} />,
    //   },
    //   { icon: IconActivity, label: "Activity" },
    {
      icon: IconLogout,
      label: "Logout",
      action: () => {
        signOut();
        navigate("/login");
      },
    },
  ];

  const { t } = useTranslation();

  const handleImageUploadSuccess = async (result) => {
    try {
      // console.log("handleImageUploadSuccess", result);
      const { data, success } = result;
      const updateResult = await api.updateById({
        apiEntity: "client",
        id: authUser()._id,
        values: { image: data.link },
      });
      // console.log("updateResult", updateResult);
      setImage(data.link);
    } catch (err) {
      console.log(err);
    }
  };

  const handleClearAfter = async () => {
    const updateResult = await api.updateById({
      apiEntity: "client",
      id: authUser()._id,
      values: { image: null },
    });
    setImage(null);
  };

  const fetchUser = async () => {
    try {
      const user = await api.getById({
        apiEntity: "client",
        id: authUser()._id,
      });
      setImage(user.image);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <AppShell.Navbar w={w}>
      <AppShell.Section mx="md" p="sm">
        {/* <Title order={5} mb="xl">
          {t(process.env.REACT_APP_GEETING)}
        </Title> */}
        {/* {isAuthenticated() ? "isAuthenticated" : "notAuthenticated"} */}
        {/* image:{image} */}
        <ImagePicker
          handleUploadSuccess={handleImageUploadSuccess}
          handleClearAfter={handleClearAfter}
          srcImage={image}
        />
        <Space h="xl" />
        {!isAuthenticated() && (
          <Group grow mt={"xl"}>
            <Button
              size="XL"
              onClick={() => {
                navigate("/login");
                toggle();
              }}
            >
              {t("Login")}
            </Button>
          </Group>
        )}
        {
          isAuthenticated() &&
            data.map((item, index) => (
              <NavLink
                href="#required-for-focus"
                key={item.label}
                label={t(item.label)}
                description={item.description}
                rightSection={item.rightSection}
                leftSection={<item.icon size="1rem" stroke={1.5} />}
                onClick={() => {
                  item.action();
                  toggle();
                }}
              />
            ))
          // <span>
          //   <Button
          //     fullWidth
          //     size="XL"
          //     onClick={() => {
          //       signOut();
          //       navigate("/login");
          //     }}
          //   >
          //     {t("Sign Out")}
          //   </Button>
          //   <Group noWrap spacing={"sm"} my="xs" gap={"xs"} justify="">
          //     <Button
          //       variant="default"
          //       onClick={() => handleChangeLanguage("en")}
          //     >
          //       EN
          //     </Button>
          //     <Button
          //       variant="default"
          //       onClick={() => handleChangeLanguage("zh-tw")}
          //     >
          //       繁體
          //     </Button>
          //     <Button
          //       variant="default"
          //       onClick={() => handleChangeLanguage("zh-tw")}
          //     >
          //       簡体
          //     </Button>
          //   </Group>
          // </span>
        }
      </AppShell.Section>
    </AppShell.Navbar>
  );
};

export default NavBar;
