import React, { useState, useEffect } from "react";
import { useAuthUser } from "react-auth-kit";
import { useServerApi } from "../hooks/useServerApi";
import { useTranslation } from "react-i18next";
import "to-color-string";
import _ from "lodash";
import {
  Text,
  Accordion,
  Group,
  ActionIcon,
  Badge,
  Title,
  Box,
  Divider,
} from "@mantine/core";
import { useCellRender } from "../hooks/useCellRender";
import moment from "moment";
import { IconBox, IconGift, IconMoneybag } from "@tabler/icons-react";
import { useWindowScroll } from "@mantine/hooks";
import InfiniteScroll from "react-infinite-scroll-component";
import "to-color-string";
import { useFormatter } from "../hooks/useFomatter";

const Item = ({ item }) => {
  const { t } = useTranslation();
  const [cellRender] = useCellRender();

  return (
    <Accordion.Item key={item._id} value={item._id} bg="white">
      <Accordion.Control>
        <Group align="flex-start" justify="space-between">
          <Group align="flex-start">
            <span>
              <Group gap="2">
                <Badge
                  radius={5}
                  size="xs"
                  color={item.status === "ACTIVE" ? "TEAL" : "RED"}
                >
                  {t(item.status)}
                </Badge>
                <Badge radius={5} size="xs" color={item.type.toColorString()}>
                  {t(item.type)}
                </Badge>
                <Badge radius={5} size="xs" color={item.type.toColorString()}>
                  {t(item.level)}
                </Badge>
              </Group>
              <Text size="xs">{item.code}</Text>
              <Group>
                <Text c="dimmed" size="xs">
                  {moment(item.paymentDate).format("YYYY-MM-DD HH:mm")}
                </Text>
              </Group>
            </span>
          </Group>
          <span>
            <Text fw={700} size="xs">
              {item.amount > 0 ? "+" : ""}
              {cellRender.NumberFormat(item.amount)}
            </Text>
            <Text size="xs" c={"dimmed"}>
              {moment(item.createdAt).format("YYYY-MM-DD")}
            </Text>
          </span>
        </Group>
      </Accordion.Control>
      <Accordion.Panel>
        <Text size="xs" c={"dimmed"}>
          {t("Order")}: {item.order?.code}
        </Text>
        <Text size="xs" c={"dimmed"}>
          {t("Product")} : {item.order?.productSnapshot?.name}
        </Text>
        <Text size="xs" c={"dimmed"}>
          {t("Order Date")}: {item.order?.paymentDate}
        </Text>
        <Text size="xs" c={"dimmed"}>
          {t("Client")}: {item.order?.client?.name}
        </Text>
      </Accordion.Panel>
    </Accordion.Item>
  );
};
const Credits = () => {
  const authUser = useAuthUser();
  const userId = authUser()._id;
  const [api] = useServerApi();
  const [items, setItems] = useState([]);
  const { t } = useTranslation();

  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [clientCredits, setClientCredits] = useState(0);
  const [formatter] = useFormatter();

  const fetchClientTotalCredt = async () => {
    try {
      const result = await api.Client.getActiveCredit(userId);
      setClientCredits(result);
    } catch (error) {
      console.error(error);
    }
  };

  /**
   *
   * @param {*} userId
   */
  const fetch = async () => {
    try {
      if (!userId) return;
      //   console.log("fetchOrders", currentPage);
      const result = await api.search({
        apiEntity: "credit",
        currentPage,
        pageSize: 50,
        searchQuery: { client: userId },
      });

      if (!result || !result.docs) return;
      setItems((prev) => _.uniqBy([...prev, ...result.docs], "_id"));
      setTotal(result.total);
      setCurrentPage(currentPage + 1);

      // console.log("result", result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!userId) return;
    fetch();
    fetchClientTotalCredt();
  }, [userId]);

  return (
    <>
      <Group justify="space-between" mb="md">
        <Group>
          <Title order={4}>{t("Credits Records")}</Title>
          <Badge color="gray">{total}</Badge>
        </Group>

        <div>
          <Title order={5}>{formatter.currencyFormat(clientCredits, "")}</Title>
          <Text c="dimmed" size="xs">
            {t("Active Credits")}
          </Text>
        </div>
        {/* <Badge color="gray">{orders.length}</Badge> */}
      </Group>
      <InfiniteScroll
        dataLength={items.length} //This is important field to render the next data
        next={fetch}
        hasMore={items.length < total}
        endMessage={
          <Text ta="center" mt="xl" c="dimmed">
            {t("No more items")}
          </Text>
        }
        loader={<h4>Loading...</h4>}
      >
        <Accordion mt="md" variant="separated" multiple={true} chevron={null}>
          {items.map((item) => (
            <Item key={item._id} item={item} />
          ))}
        </Accordion>
      </InfiniteScroll>
    </>
  );
};

export default Credits;
