import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Title, Card, Text, Group, Badge } from "@mantine/core";
import { useAuthUser } from "react-auth-kit";
import { useServerApi } from "../hooks/useServerApi";
import { useFormatter } from "../hooks/useFomatter";
import { useNavigate } from "react-router-dom";

const HomeSummary = ({}) => {
  const { t } = useTranslation();
  const authUser = useAuthUser();
  const [credit, setCredit] = useState(0);
  const [api] = useServerApi();
  const [formatter] = useFormatter();
  const navigate = useNavigate();

  const userId = authUser()._id;

  const serviceCatId = "661cec6daf542f377662c1e1";

  const fetch = async (userId) => {
    try {
      const credit = await api.Client.getActiveCredit(userId);
      setCredit(credit);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!userId) return;
    fetch(userId);
  }, [userId]);

  return (
    <>
      {/* {authUser()._id} */}
      <Group>
        <Title order={4}>{`${t("Hi")}, ${authUser().name}`}</Title>
        <Badge color="pink" radius={5}>
          {authUser().positionName}
        </Badge>
      </Group>

      <Group grow>
        <Card mt="md" title="Your Credits" px={"xl"} py="xl" w={180} h={100}>
          <Card.Section>
            <Text size="xs">{t("Active Credits")}</Text>
          </Card.Section>
          <Card.Section>
            <Title order={2}>{formatter.currencyFormat(credit, "")}</Title>
          </Card.Section>
        </Card>
        <Card mt="md" title="E-Shop" px={"xl"} py="xl" h={100}>
          <Card.Section>
            <Text size="xs">{t("All Services")}</Text>
          </Card.Section>
          <Card.Section
            onClick={() => {
              navigate(`/productCat?id=${serviceCatId}`);
            }}
          >
            <Title order={3}>{"E-SHOP"}</Title>
            <Text size="xs" c={"yellow"}>
              Go {">"}
            </Text>
          </Card.Section>
        </Card>
      </Group>
    </>
  );
};

export default HomeSummary;
