import React, { useState, useEffect } from "react";
import { useAuthUser } from "react-auth-kit";
import { useServerApi } from "../hooks/useServerApi";
import { useTranslation } from "react-i18next";
import "to-color-string";
import _ from "lodash";

import {
  Text,
  Accordion,
  Group,
  ActionIcon,
  Badge,
  Title,
  Box,
  Timeline,
} from "@mantine/core";
import { useCellRender } from "../hooks/useCellRender";
import moment from "moment";
import { IconBox, IconGift } from "@tabler/icons-react";
import { useWindowScroll } from "@mantine/hooks";
import InfiniteScroll from "react-infinite-scroll-component";

const OrderItem = ({ item }) => {
  const { t } = useTranslation();
  const [cellRender] = useCellRender();

  return (
    <Accordion.Item key={item._id} value={item._id} bg="white">
      <Accordion.Control>
        <Group align="flex-start" justify="space-between">
          <Group>
            <Box>
              <Group gap="2">
                <Badge radius={5} size="xs" color={item.status.toColorString()}>
                  {t(item.status)}
                </Badge>
                {item.operationStatus && (
                  <Badge
                    radius={5}
                    size="xs"
                    color={item.operationStatus?.toColorString()}
                  >
                    {t(item.operationStatus)}
                  </Badge>
                )}
                {item.isBundle && (
                  <Badge radius={5} size="xs">
                    {t("Bundle")}
                  </Badge>
                )}
                {item.paybackCount > 0 && item.fullyPayBack && (
                  <Badge radius={5} size="xs" color="green">
                    {t("Fully Payback")}
                  </Badge>
                )}
              </Group>
              <Text size="xs">{item.code}</Text>
              <Text size="xs" fw={700}>
                {item.product.name}
              </Text>
              <Group>
                {item.orderDate && (
                  <Text c="dimmed" size="xs">
                    {moment(item.orderDate).format("YYYY-MM-DD HH:mm")}
                  </Text>
                )}
                <Text> {item.product.category?.name} </Text>
              </Group>
            </Box>
          </Group>
          <Text fw={700} size="xs">
            {cellRender.Currency(item.payment)}
          </Text>
        </Group>
      </Accordion.Control>
      <Accordion.Panel>
        <Text size="xs" c="dimmed">
          {t("Payment")} : {cellRender.Currency(item.payment)}
        </Text>
        <Text size="xs" c="dimmed">
          {t("Payment Date")} :{" "}
          {item.paymentDate
            ? moment(item.paymentDate).format("YYYY-MM-DD")
            : "--"}
        </Text>
        <Text size="xs" c="dimmed">
          {t("Product Credit")} : {item.product?.credit}
        </Text>
        <Text size="xs" c="dimmed">
          {t("Payment Method")} : {item.paymentMethod}
        </Text>
        <Timeline bulletSize={10} mt="xl">
          {item.operationLog &&
            item.operationLog
              .filter((l) => l.showToClient)
              .map((log) => (
                <Timeline.Item>
                  <Text c="dimmed" size="sm">
                    {log.content}
                  </Text>
                  <Text size="xs">{moment(log.date).format("YYYY-MM-DD")}</Text>
                </Timeline.Item>
              ))}
        </Timeline>
      </Accordion.Panel>
    </Accordion.Item>
  );
};
const Orders = ({ showTitle = true }) => {
  const authUser = useAuthUser();
  const userId = authUser()._id;
  const [api] = useServerApi();
  const [orders, setOrders] = useState([]);
  const { t } = useTranslation();

  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  /**
   *
   * @param {*} userId
   */
  const fetchOrders = async (userId) => {
    try {
      if (!userId) return;
      const result = await api.search({
        apiEntity: "order",
        currentPage,
        pageSize: 50,
        searchQuery: { client: userId },
      });

      if (!result || !result.docs) return;
      // console.log("fetchOrders", userId, result.docs.length);

      setOrders((prev) => _.uniqBy([...prev, ...result.docs], "_id"));
      setTotal(result.total);
      setCurrentPage(currentPage + 1);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!userId) return;
    fetchOrders(userId);
  }, [userId]);

  return (
    <>
      {/* {userId} */}
      {showTitle && (
        <Group>
          {<Title order={4}>{t("My Orders")}</Title>}
          <Badge color="gray">{total}</Badge>
          {/* <Badge color="gray">{orders.length}</Badge> */}
        </Group>
      )}
      {!showTitle && (
        <Group>
          <Text size="xs" ml="xs">
            {t("My Orders")}
          </Text>
          <Badge color="gray">{total}</Badge>
        </Group>
      )}
      <InfiniteScroll
        dataLength={orders.length} //This is important field to render the next data
        next={fetchOrders}
        hasMore={orders.length < total}
        endMessage={
          <Text ta="center" mt="xl" c="dimmed">
            {t("No more items")}
          </Text>
        }
        loader={<h4>Loading...</h4>}
      >
        <Accordion mt="md" variant="separated" multiple={true} chevron={null}>
          {orders.map((item) => (
            <OrderItem key={item._id} item={item} />
          ))}
        </Accordion>
      </InfiniteScroll>
    </>
  );
};

export default Orders;
