import { useState, useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  useNavigate,
} from "react-router-dom";

import {
  AppShell,
  Header,
  Footer,
  Aside,
  Text,
  ScrollArea,
  Burger,
  useMantineTheme,
  Group,
  TextInput,
  Box,
  Switch,
  useComputedColorScheme,
} from "@mantine/core";
import { useColorScheme, useDisclosure } from "@mantine/hooks";
import NavBar from "../components/navbar";
import { ActionIcon } from "@mantine/core";
import { IconChevronCompactLeft } from "@tabler/icons-react";
import { useLocation } from "react-router-dom";

export default function Layout() {
  const [opened, { toggle }] = useDisclosure();
  const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme();
  const navBarWidth = 280;
  const headerHeight = 50;
  const navigate = useNavigate();
  const location = useLocation();
  const isHomePage = location.pathname === "/home";

  return (
    <AppShell
      navbar={{
        width: navBarWidth,
        breakpoint: "sm",
        collapsed: { mobile: !opened },
      }}
      padding="md"
      bg={theme.background[colorScheme]}
    >
      <AppShell.Header mb={"xl"}>
        <Group
          h={headerHeight}
          justify={isHomePage ? "right" : "space-between"}
          px="10"
        >
          {!isHomePage && (
            <IconChevronCompactLeft onClick={() => navigate(-1)} />
          )}
          <Burger opened={opened} onClick={toggle} size="md" />
        </Group>
      </AppShell.Header>

      <AppShell.Navbar w={navBarWidth}>
        <NavBar toggle={toggle} w={navBarWidth} />
      </AppShell.Navbar>
      {/* <Outlet /> */}

      <AppShell.Main pt={headerHeight + 15}>
        <Outlet />
      </AppShell.Main>
    </AppShell>
  );
}
