import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Card, Text, Group, ActionIcon, Flex } from "@mantine/core";
import {
  IconHeart,
  IconBook,
  IconHierarchy,
  IconServicemark,
  IconSpeakerphone,
} from "@tabler/icons-react";
import { useServerApi } from "../hooks/useServerApi";
import { useNavigate } from "react-router-dom";
import "to-color-string";
const ProductCatSection = ({}) => {
  const mt = 50;
  const { t } = useTranslation();
  const [api] = useServerApi();
  const [productCats, setProductCats] = useState([]);
  const navigate = useNavigate();
  const fetch = async () => {
    try {
      const result = await api.search({
        apiEntity: "productCategory",
        pageSize: 10,
        searchQuery: {},
        select: "cname name code description",
      });
      if (!result || !result.docs) return;

      const cats = result.docs;
      cats.forEach((c) => {
        switch (c.name) {
          case "Course":
            c.icon = IconBook;
            break;
          case "Service":
            c.icon = IconServicemark;
            break;
          case "Position":
            c.icon = IconHierarchy;
            break;

          default:
            c.icon = IconHeart;
        }
      });
      setProductCats(cats);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetch();
  }, []);
  return (
    <Card mt="md" title="" p={"xl"}>
      {/* <Card.Section mb="md">
          <Text size="xs">{t("All Products")}</Text>
        </Card.Section> */}
      <Card.Section mb="xs">
        <Text size="xs">{t("All Products")}</Text>
      </Card.Section>
      <Card.Section>
        <Group wrap="noWrap">
          {productCats.map((c) => (
            <Flex align="center" direction="column" w={70}>
              <ActionIcon
                //   variant="gradient"
                size="50"
                aria-label="Gradient action icon"
                color={c.name?.toColorString()}
                onClick={() => {
                  navigate(`/productCat?id=${c._id}`);
                }}
                //   gradient={{ from: "teal", to: "cyan", deg: 234 }}
              >
                <c.icon />
              </ActionIcon>
              <Text size="xs"> {c.cname}</Text>
            </Flex>
          ))}
        </Group>
      </Card.Section>
    </Card>
  );
};

export default ProductCatSection;
