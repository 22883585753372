import React, { useState, useEffect } from "react";
import { useAuthUser } from "react-auth-kit";
import { useServerApi } from "../hooks/useServerApi";
import { useTranslation } from "react-i18next";
import "to-color-string";
import _ from "lodash";
import {
  Text,
  Accordion,
  Group,
  ActionIcon,
  Badge,
  Table,
  Title,
  Box,
  Divider,
} from "@mantine/core";
import { useCellRender } from "../hooks/useCellRender";
import moment from "moment";
import { IconBox, IconGift, IconMoneybag } from "@tabler/icons-react";
import { useWindowScroll } from "@mantine/hooks";
import InfiniteScroll from "react-infinite-scroll-component";
import "to-color-string";
const PayrollItem = ({ item }) => {
  const { t } = useTranslation();
  const [cellRender] = useCellRender();

  return (
    <Accordion.Item key={item._id} value={item._id} bg="white">
      <Accordion.Control>
        <Group align="flex-start" justify="space-between">
          <Group align="flex-start">
            <span>
              <Badge radius={5} size="xs" color={item.status.toColorString()}>
                {t(item.status)}
              </Badge>
              <Text size="xs">{item.payrollCode}</Text>
              <Group>
                <Text c="dimmed" size="xs">
                  {moment(item.paymentDate).format("YYYY-MM-DD HH:mm")}
                </Text>
                <Text> {item.product} </Text>
              </Group>
            </span>
          </Group>
          <span>
            <Text fw={700} size="xs">
              {cellRender.Currency(item.total)}
            </Text>
            <Text size="xs" c={"dimmed"}>
              {moment(item.createdAt).format("YYYY-MM-DD")}
            </Text>
          </span>
        </Group>
      </Accordion.Control>
      <Accordion.Panel>
        <Table withRowBorders={false} striped>
          <Table.Thead></Table.Thead>
          <Table.Tbody>
            {item.commissions.map((c) => (
              <Table.Tr>
                <Table.Td>
                  <Group justify="space-between" align="flex-start">
                    <span>
                      <Group gap="2">
                        <Badge
                          radius={5}
                          size="xs"
                          color={c.status.toColorString()}
                        >
                          {t(c.status)}
                        </Badge>
                        <Badge
                          radius={5}
                          size="xs"
                          color={c.type.toColorString()}
                        >
                          {t(c.type)}
                        </Badge>
                        <Badge
                          radius={5}
                          size="xs"
                          color={c.type.toColorString()}
                        >
                          {t(c.level)}
                        </Badge>
                      </Group>

                      <Text size="xs">{c.orderCode}</Text>
                      <Text size="xs" c="dimmed">
                        {c.product}
                      </Text>
                    </span>

                    <Text fw={700} size="xs">
                      {cellRender.Currency(c.amount)}
                    </Text>
                  </Group>
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </Accordion.Panel>
    </Accordion.Item>
  );
};
const Payrolls = () => {
  const authUser = useAuthUser();
  const userId = authUser()._id;
  const [api] = useServerApi();
  const [payrollPayment, setPayrollPayment] = useState([]);
  const { t } = useTranslation();

  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  /**
   *
   * @param {*} userId
   */
  const fetch = async () => {
    try {
      if (!userId) return;
      // console.log("payrolls", payrollPayment);
      const result = await api.search({
        apiEntity: "payrollPayment",
        currentPage,
        pageSize: 50,
        searchQuery: { client: userId },
      });

      if (!result || !result.docs) return;
      setPayrollPayment((prev) => _.uniqBy([...prev, ...result.docs], "_id"));
      setTotal(result.total);
      setCurrentPage(currentPage + 1);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!userId) return;
    fetch();
  }, [userId]);

  return (
    <>
      <Group
      // style={{
      //   position: "sticky",
      //   top: 50,
      //   background: "white",
      //   zIndex: 100,
      // }}
      >
        <Title order={4}>{t("My Payrolls")}</Title>
        <Badge color="gray">{total}</Badge>
        {/* <Badge color="gray">{orders.length}</Badge> */}
      </Group>
      <InfiniteScroll
        dataLength={payrollPayment.length} //This is important field to render the next data
        next={fetch}
        hasMore={payrollPayment.length < total}
        endMessage={
          <Text ta="center" mt="xl" c="dimmed">
            {t("No more items")}
          </Text>
        }
        loader={<h4>Loading...</h4>}
      >
        <Accordion mt="md" variant="separated" multiple={true} chevron={null}>
          {payrollPayment.map((item) => (
            <PayrollItem key={item._id} item={item} />
          ))}
        </Accordion>
      </InfiniteScroll>
    </>
  );
};

export default Payrolls;
