import React, { useState, useEffect } from "react";
import { useAuthUser } from "react-auth-kit";
import { useServerApi } from "../hooks/useServerApi";
import { useTranslation } from "react-i18next";
import "to-color-string";
import _ from "lodash";
import { Title, PasswordInput, Button, Paper } from "@mantine/core";
import { useCellRender } from "../hooks/useCellRender";
import moment from "moment";
import { IconBox, IconGift, IconMoneybag } from "@tabler/icons-react";
import { useWindowScroll } from "@mantine/hooks";
import InfiniteScroll from "react-infinite-scroll-component";
import "to-color-string";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";

const Settings = () => {
  const authUser = useAuthUser();
  const userId = authUser()._id;
  const [api] = useServerApi();
  const [payrollPayment, setPayrollPayment] = useState([]);
  const { t } = useTranslation();

  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const form = useForm({
    initialValues: {
      newPassword: "",
      newPassword2: "",
    },

    validate: {
      newPassword: (value) =>
        value?.length < 5 ? t("Password length must larger than 5") : null,

      newPassword2: (value, values) =>
        value !== values.newPassword ? t("Confirm password not match") : null,
    },
  });

  const handleSubmit = async () => {
    try {
      await api.Client.changePassword({
        id: userId,
        password: form.values.newPassword,
      });

      showNotification({
        title: t("Password updated"),
        message: t("Your password has been updated successfully"),
        color: "teal",
      });
    } catch (error) {
      showNotification({
        title: t("Error"),
        message: error.message,
        color: "red",
      });
    }
  };

  return (
    <>
      <Title order={4} mb="xl">
        {t("Settings")}
      </Title>

      <Paper title={"Reset your password"} p={"xl"}>
        <PasswordInput
          label={t("New Password")}
          placeholder={t("Your new password")}
          required
          mt="md"
          variant="filled"
          {...form.getInputProps("newPassword")}
        />
        <PasswordInput
          label={t("Confirm Password")}
          placeholder={t("Your new password")}
          required
          mt="md"
          mb="md"
          variant="filled"
          {...form.getInputProps("newPassword2")}
        />

        <Button
          onClick={handleSubmit}
          disabled={!form.isValid()}
          mt="xl"
          fullWidth
        >
          {t("Save")}
        </Button>
      </Paper>

      {/* <Badge color="gray">{orders.length}</Badge> */}
    </>
  );
};

export default Settings;
