import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";
// import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

import { useServerApi } from "../hooks/useServerApi";
import { useCellRender } from "../hooks/useCellRender";
import { Title, Text, Group, Button } from "@mantine/core";
import { useTranslation } from "react-i18next";
import ReactQuill, { Quill } from "react-quill";

const Product = ({}) => {
  const [product, setProduct] = useState([]);
  const [credit, setCredit] = useState(0);

  const [cellRender] = useCellRender();
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("id");

  const [api] = useServerApi();
  const { t } = useTranslation();
  const authUser = useAuthUser();
  const userId = authUser()._id;

  const fetch = async () => {
    try {
      if (!id) return;
      const credit = await api.Client.getActiveCredit(userId);
      setCredit(credit);

      const product = await api.getById({
        apiEntity: "product",
        id,
      });
      // console.log("Fetch product data", product);
      if (product) setProduct(product);
    } catch (error) {
      console.error(error);
    }
  };

  const goback = () => {
    window.history.back();
  };

  const purchase = async () => {
    try {
      if (!id || !product || !userId) return;
      //Confirm by browser
      if (!window.confirm(t("Are you sure to purchase this product? "))) return;
      // console.log("Purchase product", product);
      const values = {
        product: product._id,
        client: userId,
        operationStatus: "PENDING",
        status: product.price == 0 ? "PAID" : "WAIT",
        paymentStatus: product.price == 0 ? "PAID" : "WAIT",
        productSnapshot: product,
        paymentDate: product.price == 0 ? new Date() : null,
        creditExpense: product.creditNeed,
        total: product.price,
        netTotal: product.price,
        payment: 0,
        discount: 0,
        fullyPayBack: false,
      };

      // console.log("Purchase product", product, values);
      const result = await api.add({
        apiEntity: "order",
        values,
      });

      // console.log("Order created", result);
      // return;
      if (result) {
        console.log("Order created", result);
        window.location.href = `/orders?id=${result._id}`;
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetch();
  }, [id]);

  return (
    <>
      <Title order={4}>{product.name}</Title>

      {/* <Text>{product.eshopDescription}</Text> */}
      <Group mb="md" justify="space-between" mr="xl">
        <Group>
          {product.price && (
            <>
              <Text>{t("Price")}</Text>
              <Text>{cellRender.Currency(product.price)}</Text>
            </>
          )}

          {product.creditNeed && (
            <>
              <Text>{t("Credit Need")}</Text>
              <Text>{cellRender.NumberFormat(product?.creditNeed)}</Text>
            </>
          )}
        </Group>

        <Group wrap="nowrap" gap={2}>
          <Button onClick={purchase} disabled={credit < product?.creditNeed}>
            {t("Purchase")}
          </Button>
          <Button onClick={goback} variant="default">
            {t("Back")}
          </Button>
        </Group>
      </Group>

      <ReactQuill
        readOnly={true}
        value={product.eshopDescription}
        theme={"bubble"}
      />
    </>
  );
};

export default Product;
