import React, { useEffect, useState } from "react";
import { AddToCalendarButton } from "add-to-calendar-button-react";

import { Calendar } from "@mantine/dates";
import {
  Group,
  Indicator,
  Table,
  Text,
  Box,
  Badge,
  Grid,
  ScrollArea,
  UnstyledButton,
  Card,
  Title,
} from "@mantine/core";
import moment from "moment";
import _ from "lodash";

import { useCellRender, openWhatsapp } from "../hooks/useCellRender";
import {
  IconBrandWhatsapp,
  IconCalendar,
  IconClock,
  IconPhone,
  IconExternalLink,
  IconMap,
  IconCopy,
} from "@tabler/icons-react";
import { useServerApi } from "../hooks/useServerApi";
import { useAuthUser } from "react-auth-kit";
import { useTranslation } from "react-i18next";

export const getEventColor = (type) => {
  switch (type) {
    case "companyEvent":
      return "red";
    case "inTraining":
      return "yellow";
    case "meeting":
      return "orange";
    case "birthday":
      return "cyan";
    default:
      return "blue";
  }
};

const EventCalendar = ({ events, onMonthChange }) => {
  const eventDates = events?.map((e) => moment(e.date)) ?? [];

  const isEventDate = (date) => {
    if (moment().isSame(date, "date")) return true;
    for (const d of eventDates) {
      if (d?.isSame(date, "date")) return true;
    }
    if (eventDates.include(date.get)) return false;
  };

  return (
    <div style={{ width: "100%" }}>
      <Calendar
        w={"100%"}
        onMonthChange={onMonthChange}
        onChange={() => {}}
        renderDay={(date) => {
          const day = date.getDate();
          const event = events?.find((e) =>
            moment(e.date).isSame(date.toISOString(), "date")
          );
          const isToday = moment().isSame(date.toISOString(), "date");
          return (
            <>
              {event && (
                <Indicator
                  size={6}
                  color={getEventColor(event.type)}
                  zIndex={1}
                >
                  <div>{day}</div>
                </Indicator>
              )}
              {!event && isToday && (
                <Indicator size={6} color="teal" zIndex={1}>
                  <div>{day}</div>
                </Indicator>
              )}
              {!event && !isToday && <div>{day}</div>}
            </>
          );
        }}
      />

      <Box mt={"xl"}>
        <Group>
          <Indicator size={6} color="teal" zIndex={1} />
          <Text size="xs">Today</Text>
        </Group>
        <Group noWrap>
          <Indicator size={6} color="red" zIndex={1} />
          <Text size="xs">Company Event</Text>
        </Group>
        <Group>
          <Indicator size={6} color="yellow" zIndex={1} />
          <Text size="xs">Internal Training</Text>
        </Group>
        <Group>
          <Indicator size={6} color="orange" zIndex={1} />
          <Text size="xs">Meeting</Text>
        </Group>
        <Group>
          <Indicator size={6} color="cyan" zIndex={1} />
          <Text size="xs">Client Birthday</Text>
        </Group>
      </Box>
    </div>
  );
};

const EventSection = ({
  title,
  txtSize = "xs",
  headerSize = "xs",
  iconSize = 12,
  ...props
}) => {
  const [cellRender] = useCellRender();
  const [events, setEvents] = useState([]);
  const [clients, setClients] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [api] = useServerApi();
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);

  const auth = useAuthUser();

  const fetchEvent = async () => {
    const e = await api.CalendarEvent.getByMonth(currentDate);
    setEvents(e);
  };

  useEffect(() => {
    fetchEvent();
  }, [currentDate]);

  const rows = events?.map((ev, index) => {
    return (
      <Box mb="md" key={"e" + index}>
        <AddToCalendarButton
          buttonStyle="date"
          size="8"
          name={ev.title}
          startDate={moment(ev.date).format("YYYY-MM-DD")}
          endDate={moment(ev.date).format("YYYY-MM-DD")}
          startTime={ev.fromTime}
          endTime={ev.toTime}
          location={ev.venues}
          description={ev.description}
          forceOverlay={true}
          options={[
            "Apple",
            "Google",
            "Yahoo",
            "iCal",
            "Outlook.com",
            "Microsoft365",
          ]}
          timeZone="Asia/Hong_Kong"
          // label="+"
          language="zh"

          // hideIconButton={true}
        />
      </Box>
    );
  });

  return (
    <Box mt="xl" mb="xl">
      {events && events.length > 0 && (
        <Card mt="md" title="Your Credits">
          <Group mb="xs">
            <Text size={headerSize}>{t("Latest Activity")}</Text>
            <Badge size="xs"> {events.length ?? 0}</Badge>
          </Group>
          {rows}
        </Card>
      )}

      {/* <EventCalendar events={events} onMonthChange={setCurrentDate} /> */}
    </Box>
  );
};

export default EventSection;
