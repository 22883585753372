import React from "react";
import { useTranslation } from "react-i18next";
import { Space } from "@mantine/core";
import { IconHeart } from "@tabler/icons-react";

import { useAuthUser } from "react-auth-kit";
import { Container } from "postcss";
import { useWindowScroll } from "@mantine/hooks";
import ProductCatSection from "../components/productCatSection";
import MyHistoryMenuSection from "../components/myHistoryMenuSection";
import MyLatestActivities from "../components/myLatestActivities";
import HomeSummary from "../components/homeSummary";
import EventCalendar from "../components/eventCalendar";
import TopRanking from "../components/topRanking";
import Orders from "./orders";

const Home = ({}) => {
  return (
    <div>
      <HomeSummary />

      <MyHistoryMenuSection />
      <ProductCatSection />
      <EventCalendar />
      <TopRanking />

      <Space h="lg" />
      {/* <Orders showTitle={false} /> */}
    </div>
  );
};

export default Home;
