import React from "react";
import {
  Paper,
  createStyles,
  TextInput,
  PasswordInput,
  Checkbox,
  Button,
  Title,
  Text,
  Anchor,
  Container,
  Group,
} from "@mantine/core";
import { IconExclamationMark, IconCircleCheck } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

import { useForm } from "@mantine/form";
import { useServerApi } from "../hooks/useServerApi";
import { showNotification } from "@mantine/notifications";

import { useSignIn, useIsAuthenticated } from "react-auth-kit";
import { useNavigate } from "react-router-dom";

const ForgetPassword = () => {
  const title = "Reset Your Password";
  const [api] = useServerApi();
  const signIn = useSignIn();
  const navigate = useNavigate();
  const isAuthenicated = useIsAuthenticated();
  const { t } = useTranslation();

  if (isAuthenicated()) {
    navigate("/");
  }

  const form = useForm({
    initialValues: {
      phone: "",
    },

    validate: {
      phone: (value) =>
        value.length > 0 && value.length < 8
          ? t("Phone number must be 8 digits or above")
          : null,
    },
  });

  const handleReset = async ({ email }) => {
    // console.log(values);
    try {
      const result = await api.Client.resetPassword(email);
      // console.log(result);
      navigate("/login");
      return showNotification({
        title: "Password Reset",
        color: "green",
        icon: <IconCircleCheck size={18} />,
        message: t("New password has been reset."),
      });
      return;
    } catch (error) {
      // console.log(error);
      return showNotification({
        title: "Login Fail",
        color: "red",
        icon: <IconExclamationMark size={18} />,
        message: error.msg,
      });
    }
  };

  return (
    <Container p="xl">
      <form onSubmit={form.onSubmit((values) => handleReset(values))}>
        <Title
          align="center"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 900,
          })}
        >
          {t(title)}
        </Title>

        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
          <Text mb="xl">
            {t(
              "Please fill in your register phone number and click the reset button. Your password will be reset to first 5 digit of your phone number. You can change it later in your profile setting"
            )}
          </Text>

          <TextInput
            label={t("Phone")}
            placeholder={t("Your phone Number")}
            required
            {...form.getInputProps("phone")}
          />

          <Button fullWidth mt="xl" type="submit">
            {t("Reset Password")}
          </Button>
        </Paper>
      </form>
    </Container>
  );
};

export default ForgetPassword;
