import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";
import { AuthProvider, RequireAuth } from "react-auth-kit";
import { Notifications } from "@mantine/notifications";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { createTheme, MantineProvider } from "@mantine/core";
import { LanguageProvider } from "./context/language";

import themeObject from "./themeObject";

import Login from "./pages/login";
import Layout from "./pages/layout";
import Home from "./pages/home";
import ProductCat from "./pages/productCat";
import Orders from "./pages/orders";
import Commissions from "./pages/commissions";
import Credits from "./pages/credits";
import Payrolls from "./pages/payrolls";
import Product from "./pages/product";
import Settings from "./pages/setting";
import ForgetPassword from "./pages/forgetPassword";

const theme = createTheme(themeObject);
const authName = `${process.env.REACT_APP_AUTH_NAME}`;
const languageFromLocalStorage =
  localStorage.getItem("gbaymobile_language") || "zh-tw";

const App = () => {
  return (
    <AuthProvider authType={"localstorage"} authName={authName}>
      <MantineProvider theme={theme} defaultColorScheme="light">
        <LanguageProvider initialLanguage={languageFromLocalStorage}>
          <Notifications />

          <BrowserRouter>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/forgetPassword" element={<ForgetPassword />} />
              <Route
                path="/"
                key="route-home"
                element={
                  <RequireAuth loginPath={"/login"}>
                    <Layout />
                  </RequireAuth>
                }
              >
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/productCat" element={<ProductCat />} />
                <Route path="/product" element={<Product />} />
                <Route path="/orders" element={<Orders />} />
                <Route path="/commissions" element={<Commissions />} />
                <Route path="/credits" element={<Credits />} />
                <Route path="/payrolls" element={<Payrolls />} />
                <Route path="/settings" element={<Settings />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </LanguageProvider>
      </MantineProvider>
    </AuthProvider>
  );
};

export default App;
