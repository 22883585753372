import React from "react";
import { Text, Title, Card, Loader } from "@mantine/core";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useServerApi } from "../hooks/useServerApi";
import { Accordion, Group, Table } from "@mantine/core";
import { useFormatter } from "../hooks/useFomatter";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";
import { IconHeart, IconBookmark, IconShare } from "@tabler/icons-react";
import classes from "./ArticleCard.module.css";
import { rem } from "@mantine/core";
import { Avatar, Badge, Image, useMantineTheme } from "@mantine/core";

import { Center } from "@mantine/core";
import { ActionIcon } from "@mantine/core";

const ProductCard = ({ product: p }) => {
  const linkProps = {
    href: "https://mantine.dev",
    target: "_blank",
    rel: "noopener noreferrer",
  };
  const theme = useMantineTheme();
  const [formatter] = useFormatter();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Card
      withBorder
      radius="md"
      className={classes.card}
      mt="xl"
      onClick={() => {
        navigate(`/product?id=${p._id}`);
      }}
    >
      {p.mainImage && (
        <Card.Section mb="md">
          <Image
            src={
              p.mainImage
                ? `${process.env.REACT_APP_FILE_URL}/${p.mainImage}`
                : null
            }
            height={180}
          />
        </Card.Section>
      )}
      <Group justify="space-between">
        <Text fw={500} component="a">
          {p.name}
        </Text>
        <Text>{formatter.currency(p.price)}</Text>
      </Group>

      {p.creditNeed > 0 && (
        <Group justify="right">
          <Text size="md" c="dimmed">
            {t("Credit Need")} {p.creditNeed}
          </Text>
        </Group>
      )}
      {/* <Text fz="sm" c="dimmed" lineClamp={4} style={{ whiteSpace: "pre-wrap" }}>
        {p.description}
      </Text> */}
    </Card>
  );
};

const ProductCat = ({}) => {
  //get params id
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("id");
  const [api] = useServerApi();
  const [products, setProducts] = useState([]);
  const [cat, setCat] = useState({});
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const fetchCat = async () => {
    try {
      if (!id) return;
      const c = await api.getById({
        apiEntity: "productCategory",
        id,
      });
      if (!c) return;
      setCat(c);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchProducts = async () => {
    try {
      if (!id) return;
      setLoading(true);
      const result = await api.search({
        apiEntity: "product",
        searchQuery: { category: id, status: "ACTIVE" },
        pageSize: 200,
        select: "name cname price credit description code creditNeed mainImage",
      });
      setLoading(false);
      if (!result || !result.docs) return;
      setProducts(result.docs);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCat();
    fetchProducts();
  }, [id]);

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <>
          <Title order={4}>
            {t("Product")} > {cat.cname}
          </Title>

          {products.map((p) => (
            <ProductCard key={p._id} product={p} />
          ))}
        </>
      )}
    </>
  );
};

export default ProductCat;
