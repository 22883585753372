import React, { useState, useEffect } from "react";
import i18n from "../i18n";

const LanguageContext = React.createContext();

const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(
    localStorage.getItem("language") || "zh-tw"
  );

  const changeLanguage = (newLanguage) => {
    if (!newLanguage) return;
    console.log("changeLanguage:", newLanguage);
    setLanguage(newLanguage);
  };

  useEffect(() => {
    localStorage.setItem("language", language);
    i18n.changeLanguage(language);
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export { LanguageContext, LanguageProvider };
