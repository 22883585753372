import React from "react";
import { useTranslation } from "react-i18next";
import { Card, Text, Group, ActionIcon, Flex } from "@mantine/core";

import "to-color-string";
import { useNavigate } from "react-router-dom";
import {
  IconGauge,
  IconHome,
  IconMenuOrder,
  IconFingerprint,
  IconActivity,
  IconLogout,
  IconMoneybag,
  IconCurrencyDollar,
  IconGift,
} from "@tabler/icons-react";

const actions = [
  // {
  //   title: "All",
  //   filter: null,
  // },
  {
    title: "Orders",
    link: "/orders",
    icon: IconMenuOrder,
  },
  {
    title: "Payrolls",
    link: "/payrolls",
    icon: IconMoneybag,
  },
  {
    title: "Commissions",
    link: "/commissions",
    icon: IconCurrencyDollar,
  },
  {
    title: "Credits",
    link: "/credits",
    icon: IconGift,
  },
];

const MyHistoryMenuSection = ({}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Card mt="md" title="" p={"xl"}>
      {/* <Card.Section mb="md">
          <Text size="xs">{t("All Products")}</Text>
        </Card.Section> */}
      <Card.Section mb="xs">
        <Text size="xs">{t("My History")}</Text>
      </Card.Section>
      <Card.Section>
        <Group wrap="noWrap">
          {actions.map((c) => (
            <Flex align="center" direction="column" w={70}>
              <ActionIcon
                //   variant="gradient"
                size="50"
                aria-label="Gradient action icon"
                color={c.title.toColorString()}
                onClick={() => {
                  navigate(c.link);
                }}
              >
                <c.icon />
              </ActionIcon>
              <Text size="xs"> {t(c.title)}</Text>
            </Flex>
          ))}
        </Group>
      </Card.Section>
    </Card>
  );
};

export default MyHistoryMenuSection;
