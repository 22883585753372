import React from "react";

import {
  Paper,
  createStyles,
  TextInput,
  PasswordInput,
  Checkbox,
  Button,
  Title,
  Text,
  Flex,
  Anchor,
  Card,
  Container,
  Group,
} from "@mantine/core";
import { useServerApi } from "../hooks/useServerApi";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { IconExclamationMark } from "@tabler/icons-react";
import { useSignIn, useIsAuthenticated, useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";

const Login = ({}) => {
  const title = `${process.env.REACT_APP_WELCOME_MSG}`;
  const [api] = useServerApi();
  const signIn = useSignIn();
  const navigate = useNavigate();
  const isAuthenicated = useIsAuthenticated();
  const location = useLocation();
  const signOut = useSignOut();
  const { t: translate } = useTranslation();

  const form = useForm({
    initialValues: {
      phone: "",
      keepSign: true,
    },

    validate: {
      // email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });

  const handleError = ({ message }) => {
    // console.log("handleError", message);
    let msg = "";
    switch (message) {
      case "ERR_AUTH":
        msg = translate("Authentication error");
      case "ERR_NO_USER":
        msg = translate("No this user");
        break;
      case "ERR_USER_INACTIVE":
        msg = translate("The user is inactive");
        break;
      case "ERR_INVALID_PASSWORD":
        msg = translate("Invalid password");
        break;
      default:
        msg = message;
        break;
    }

    return showNotification({
      title: translate("Login Fail"),
      color: "red",
      icon: <IconExclamationMark size={18} />,
      message: msg,
    });
  };

  const handleSuccess = async (result) => {
    // console.log(result);
    if (!result.data) return;
    const { a, t, u, w, expiresInHr } = result.data;
    // console.log("handleSuccess Logined:", u);
    const signed = await signIn({
      token: t,
      expiresIn: expiresInHr * 60,
      tokenType: "Bearer",
      authState: {
        ...u,
        a,
      },
    });
    // console.log("Signed", result.data);
    if (signed) {
      setTimeout(() => {
        navigate("/home");
      }, 200);
    }
  };

  const handleSignIn = async ({ phone, password, keepSign }) => {
    try {
      // console.log(phone, password);
      const result = await api.Client.signIn(phone, password);
      if (!result.success) return handleError(result);
      return handleSuccess(result);
    } catch (error) {
      console.log(error);
      handleError(error);
    }
  };

  const handleForget = () => {
    // console.log("Forget");
    navigate("/forgetPassword");
  };

  return (
    <Container p="xl">
      <Title mb="xl"> {process.env.REACT_APP_NAME}</Title>
      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <form onSubmit={form.onSubmit((values) => handleSignIn(values))}>
          {/* <Paper withBorder shadow="md" p={30} mt={30} radius="md"> */}
          <TextInput
            label={translate("Phone")}
            placeholder="61218000"
            required
            {...form.getInputProps("phone")}
          />
          <PasswordInput
            label={translate("Password")}
            placeholder={translate("Your password")}
            required
            mt="md"
            {...form.getInputProps("password")}
          />
          <Group justify="space-between" mt="lg">
            <Anchor onClick={handleForget} href="#" size="sm">
              {translate("Forgot password?")}
            </Anchor>
          </Group>
          <Button fullWidth mt="xl" type="submit">
            {translate("Sign in")}
          </Button>
          {/* </Paper> */}
        </form>
      </Paper>
    </Container>
  );
};

export default Login;
