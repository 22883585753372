import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useServerApi } from "../hooks/useServerApi";
import { Text, Card, Box } from "@mantine/core";
import moment from "moment";
import { Carousel } from "@mantine/carousel";
import { useCellRender } from "../hooks/useCellRender";

const TopRanking = () => {
  const { t } = useTranslation();
  const [api] = useServerApi();
  const [rankings, setRankings] = useState([]);
  const [cellRender] = useCellRender();

  const fetch = async () => {
    try {
      const startDate = moment()
        .subtract(1, "Q")
        .endOf("quarter")
        .format("YYYY-MM-DD");

      const endDate = moment()
        .subtract(0, "Q")
        .endOf("quarter")
        .format("YYYY-MM-DD");

      const data = await api.TopRanking.getStats({ startDate, endDate });
      setRankings(data);
      // console.log("Fetch rank data", data);
      setRankings(data ?? []);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <>
      {/* <Text size="xs" mb="xs">
        {t("Top Ranking")}
      </Text> */}

      <Carousel withIndicators height={250} controlsOffset="xs">
        {rankings.map((ranking, index) => (
          <Carousel.Slide key={index}>
            <Card p={"xl"}>
              <Card.Section>
                <Text px="xs" size="xs">
                  {ranking.name}
                </Text>
              </Card.Section>
              <Card.Section mt="md" ml="md">
                {ranking.data.map((data, index) => (
                  <Box mb="xs">{cellRender.Client(data.referer)}</Box>
                ))}
              </Card.Section>
            </Card>
          </Carousel.Slide>
        ))}
        {/* ...other slides */}
      </Carousel>
    </>
  );
};

export default TopRanking;
